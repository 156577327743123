import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@hooks/useTheme'
import { useMediaQuery } from '@mui/material'
import formStyles from '@assets/css/forms.module.css'
import { ControlledFormProps } from '@controls/types'
import { ConsentLabel } from '@controls/ConsentLabel'
import { FormCheckbox, FormTextField } from '@controls/Form'
import { CustomerContactsFormValues } from './types'
import { FlexContainer } from '@layout/FlexContainer'

type Props = {
  title: string
} & ControlledFormProps<CustomerContactsFormValues>

export const CustomerContactsFormInputs: React.FC<Props> = ({ title, control, disabledControls }) => {
  const { t } = useTranslation()

  const { currentTheme } = useTheme()
  const isDownSm = useMediaQuery(currentTheme.breakpoints.down('sm'))

  return (
    <FlexContainer vertical>
      <h3 className={formStyles.formSubtitle}>{title}</h3>
      <FlexContainer>
        <FormTextField
          control={control}
          name="name"
          type="text"
          label={t('global.forms.customer.fields.name')}
          className={formStyles.required}
          disabled={disabledControls}
        />
        <FormTextField
          control={control}
          name="email"
          type="email"
          label={t('global.forms.customer.fields.email')}
          className={formStyles.required}
          disabled={disabledControls}
        />
        <FormTextField
          control={control}
          name="phoneNumber"
          type="phone"
          label={t('global.forms.customer.fields.phone')}
          className={formStyles.required}
          disabled={disabledControls}
        />
      </FlexContainer>
      <FormTextField
        control={control}
        name="additionalInfo"
        type="text"
        label={t('global.forms.customer.fields.additional_info')}
        multiline
        rows={isDownSm ? 2 : 4}
        disabled={disabledControls}
      />
      <FormCheckbox control={control} name="consent" label={<ConsentLabel />} disabled={disabledControls} />
    </FlexContainer>
  )
}
